import { Injectable } from "@angular/core";
import { HttpRequest, HttpClient, HttpEventType } from "@angular/common/http";
import { Subject } from "rxjs";
import { UploadProgress, DocumentInProcess } from "./document.analysis.models";

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private uploadingSubject$ = new Subject<UploadProgress>();
  uploading = this.uploadingSubject$.asObservable();

  private uploadedSubject$ = new Subject<DocumentInProcess>();
  uploaded = this.uploadedSubject$.asObservable();

  constructor(private http: HttpClient) { }

  public uploadFile(formData: FormData) {
    const uploadReq = new HttpRequest('POST', `api/upload`, formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.uploadingSubject$.next({
          percent: Math.round(100 * event.loaded / event.total)
        });
      else if (event.type === HttpEventType.Response)
        this.uploadedSubject$.next({
          id: event.body.toString()
        });
    });
  }
}
