import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User, Claim, Information, Ping } from './auth.models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/* eslint-disable */

const ROLE_ADMIN = 'Admin';
const ROLE_CA_MARKETING = 'CA Marketing';
const ROLE_CA_CLIENT = 'CA Client';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private CLAIM_ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
  private CLAIM_ID = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier';
  private CLAIM_FIRST_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname';
  private CLAIM_LAST_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname';
  private CLAIM_PICTURE = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/picture';

  private URL_USER_TOKEN = '/api/SecurityUserToken';
  private LOCAL_STORAGE_TOKEN_KEY = 'access_token';


  private USER_NOT_AUTHORIZED: User = {
    authenticated: false,
    id: null,
    roles: null,
    claims: null,
    isAdmin: false,
    isCAMarketing: false,
    isCAClient: false,
    displayName: null,
    image: null
  };

  constructor(private http: HttpClient) {
  }

  signout(): Observable<any> {
    return this.http.get('/Account/ClientAppLogout')
      .pipe(map(val => {
        localStorage.removeItem(this.LOCAL_STORAGE_TOKEN_KEY);
      }));
  }  

  info(): Observable<Information> {
    return this.http.get<Information>('/api/information');
  }

  getUser(): Observable<User> {

    const localStorageToken = localStorage.getItem(this.LOCAL_STORAGE_TOKEN_KEY);
    if (!!localStorageToken) {
      // Local storage key found, check it
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(localStorageToken);
      const isExpired = helper.isTokenExpired(localStorageToken);

      if (!!decodedToken && !isExpired) {
        // Token is valid and not expired, return the user

        return of({
          authenticated: true,
          id: this.getId(decodedToken),
          roles: decodedToken[this.CLAIM_ROLE] as string[],
          claims: this.getClaims(decodedToken),
          isAdmin: this.isAdmin(decodedToken),
          isCAMarketing: this.isCAMarketing(decodedToken),
          isCAClient: this.isCAClient(decodedToken),
          displayName: this.getDisplayName(decodedToken),
          image: this.getImage(decodedToken)
        });
      }
    }

    // Not valid user in local storage, get it from api
    return this.getUserFromApi();
  }

  // Gets the user from API
  private getUserFromApi(): Observable<User> {
    return this.http.get(this.URL_USER_TOKEN)
      .pipe(map(response => {
        const token = response['token'];
        if (!!token) {
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(token);

          if (!!decodedToken) {
            localStorage.setItem(this.LOCAL_STORAGE_TOKEN_KEY, response['token']);
            return {
              authenticated: true,
              id: this.getId(decodedToken),
              roles: decodedToken[this.CLAIM_ROLE] as string[],
              claims: this.getClaims(decodedToken),
              isAdmin: this.isAdmin(decodedToken),
              isCAMarketing: this.isCAMarketing(decodedToken),
              isCAClient: this.isCAClient(decodedToken),
              displayName: this.getDisplayName(decodedToken),
              image: this.getImage(decodedToken)
            };
          }
        }

        return this.USER_NOT_AUTHORIZED;
      }));
  }

  private getClaims(token: object): Claim[] {
    const claims = new Array<Claim>();
    for (const key in token) {
      if (key.length !== 3 && !key.startsWith('ldap-')) {
        const value = token[key];
        if (value instanceof Array) {
          for (const claimValue of value) {
            claims.push({ key: key, value: claimValue });
          }
        } else {
          claims.push({ key: key, value: value });
        }
      }
    }
    return claims;
  }


  private getId(token: object): string {
    const username = token[this.CLAIM_ID];
    if (username instanceof Array) {
      return token[this.CLAIM_ID][0];
    } else {
      return token[this.CLAIM_ID];
    }
  }

  private isAdmin(token: object): boolean {
    const roles = token[this.CLAIM_ROLE] as string[];
    if (!!roles) {
      return roles.indexOf(ROLE_ADMIN) >= 0;
    }
    return false;
  }

  private isCAMarketing(token: object): boolean {
    const roles = token[this.CLAIM_ROLE] as string[];
    if (!!roles) {
      return roles.indexOf(ROLE_CA_MARKETING) >= 0;
    }
    return false;
  }

  private isCAClient(token: object): boolean {
    const roles = token[this.CLAIM_ROLE] as string[];
    if (!!roles) {
      return roles.indexOf(ROLE_CA_CLIENT) >= 0;
    }
    return false;
  }

  private getDisplayName(token: object): string {
    if (token[this.CLAIM_FIRST_NAME] && token[this.CLAIM_LAST_NAME]) {
      const name = token[this.CLAIM_FIRST_NAME] + ' ' + token[this.CLAIM_LAST_NAME];
      return name;
    }

    return this.getId(token);
  }

  private getImage(token: object): string {
    return token['picture'];
  }

  ping(): Observable<any> {
    return this.http.get('/api/ping');
  }

  removeAccessToken() {
    localStorage.removeItem(this.LOCAL_STORAGE_TOKEN_KEY);
  }
}
