import {Component, Input, OnDestroy, OnInit, SecurityContext} from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import * as octicons from '@primer/octicons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeaturesService } from '../features.service';
import { PatternGroup } from '../text-analysis.models';
import {DomSanitizer} from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-text-highlight',
  templateUrl: './text-highlight.component.html',
  styleUrls: ['./text-highlight.component.scss']
})
export class TextHighlightComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  @Input() textContent = '';

  _patternGroups: PatternGroup[];
  @Input('patternGroups')
  set patternGroups(value: PatternGroup[]) {
    this.processPatternGroups(value);
  }

  htmlContent: string = '';

  constructor(private featuresService: FeaturesService,
    private localStorage: LocalStorage,
              private domSanitizer: DomSanitizer) {

  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  getHtmlHighlights(patternGroups: PatternGroup[]): string {
    const textContent = this.textContent;
    if (patternGroups?.length === 0) {
      return textContent;
    }

    let text = '';

    patternGroups?.push({
      features: [{ feature: '', weight: 1, uiName: '' }],
      from: 1000000,
      to: 1000001
    });

    for (let i = 0; i < textContent?.length && patternGroups?.length > 0; i++) {
      let group = patternGroups[0];
      if (i > group?.to) {
        patternGroups?.shift();
        group = patternGroups[0];
      }

      if (i === group?.from) {
        const tooltip = this.generatePopoverContent(group);
        const redFeature = group?.features?.find(pat => pat?.weight < 0);
        const greenFeature = group?.features?.find(pat => pat?.weight >= 0);

        const highlightClass = 'highlight-' + ((redFeature && greenFeature) ? 'yellow' : (redFeature ? 'red' : 'green'));
        text += '<span class="' + highlightClass + ' pattern">';
        text += '<span class="patterntooltip">' + tooltip + '</span>';
      }

      if (textContent[i] === '\n') {
        text += '<br/>';
      } else {
        text += textContent[i];
      }


      if (i === group?.to) {
        text += '</span>';
      }
    }

    return text;
  }

  generatePopoverContent(group: PatternGroup) {
    let container = $('<table>').css('width', '200px').css('margin', '10px');

    group?.features?.forEach(feature => {
      let row = $('<tr>');
      container?.append(row);
      row.css('color', feature?.weight < 0 ? 'red' : 'green');

      let iconColumn = $('<td>').css('width', '20px');
      row.append(iconColumn);

      let iconName = feature?.weight < -0.3 ? 'arrow-down' : 'arrow-up';
     
      let icon = $(octicons[iconName].toSVG());
      iconColumn?.append(icon);

      let textColumn = $('<td>').css('width', '180px');
      let weight = Math.round(feature?.weight * 100);
      textColumn?.append(`${feature?.uiName} (${weight}%)`);
      row.append(textColumn);
    });

    return $('<div>').append(container).html();
  }

  private processPatternGroups(patternGroups: PatternGroup[]) {

    this.localStorage?.getItem<string>('lang')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lang: string) => {

        this.featuresService.getData(lang)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((features) => {

            patternGroups?.forEach(pg => {
              pg?.features?.forEach(pgf => {
                let feature = features?.find(f => f.name === pgf.feature);
                pgf.uiName = feature ? feature?.uiName : pgf?.feature;
              });
            });

            this._patternGroups = patternGroups;
            this.htmlContent = this.getHtmlHighlights(patternGroups);
          });
      });
  }
}
