import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FeaturesService } from "../features.service";
import { ComparisonResultItem, Feature, TextAnalysisMode } from "../text-analysis.models";
import { TextAnalysisService } from "../text-analysis.service";

@Component({
  selector: 'app-compare-result',
  templateUrl: './compare-result.component.html',
  styleUrls: ['./compare-result.component.scss']
})
export class CompareResultComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();

  @Input()
  textAnalysisMode$: Subject<TextAnalysisMode>;

  showResults: boolean;

  comparisonResult: ComparisonResultItem[];

  allFeatures: Feature[] = [];

  constructor(private textAnalysisService: TextAnalysisService,
    private featuresService: FeaturesService,
    private localStorage: LocalStorage,
    private translate: TranslateService) {

  }

  ngOnInit(): void {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getAllFeatures(event.lang);
    });

    this.localStorage.getItem<string>('lang')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lang: string) => {
        this.getAllFeatures(lang);
      });     

    this.textAnalysisMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.showResults = response === TextAnalysisMode.analysisMode ? true : false;
      });


    this.textAnalysisService.comparedTexts$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.comparisonResult = response.leftResults
          .sort((a, b) => a.percentile > b.percentile ? -1 : 1)
          .map((result) => {
            let feature: Feature = this.allFeatures.find(f => f.name === result.feature);
            let rightResult = response.rightResults.find(r => r.feature === result.feature);

            let leftFeature: Feature = {
              description: feature.description,
              longDescription: feature.longDescription,
              name: feature.name,
              score: result.percentile,
              selected: false,
              uiName: feature.uiName
            };

            let rightFeature: Feature = {
              description: feature.description,
              longDescription: feature.longDescription,
              name: feature.name,
              score: rightResult.percentile,
              selected: false,
              uiName: feature.uiName
            };

            let comparison: ComparisonResultItem = {
              leftFeature: leftFeature,
              rightFeature: rightFeature
            }

            return comparison;
          });
      });
  }

  private getAllFeatures(lang: string) {
    this.featuresService.getData(lang)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.allFeatures = data;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }
}
