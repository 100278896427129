import { OnInit, OnDestroy, Component } from "@angular/core";
import { Subject } from "rxjs";
import { SelectedCriteria, Profile, TextAnalysisMode } from "./text-analysis.models";
import { SessionStorageService } from "./session-storage.service";

@Component({
  selector: 'app-text-analysis',
  templateUrl: './text-analysis.component.html',
  styleUrls: ['./text-analysis.component.scss']
})
export class TextAnalysisComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  selectedCriteria$ = new Subject<SelectedCriteria>();
  selectedProfile$ = new Subject<Profile>();
  textAnalysisMode$ = new Subject<TextAnalysisMode>();
  includePatterns: boolean = this.sessionStorage.getValueFromSession("includePatterns") === 'false' ? false : true;
  maxContentLength = this.includePatterns ? 10000 : 25000;

  constructor(private sessionStorage: SessionStorageService) {
    
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  onIncludePatternsChanged(included: boolean) {
    this.maxContentLength = included ? 10000 : 25000;
    this.includePatterns = included;
  }
}
