<div class="row">
  <div class="col-auto mr-auto">
    <h4>{{ 'textAnalysis.selectCriteria' | translate }}</h4>
  </div>
  <div class="col-auto">
    <button class="btn btn-link btn-sm"
            (click)="selectAllFeatures(true)">
      {{ 'textAnalysis.selectAll' | translate }}
    </button>
    <button class="btn btn-link btn-sm"
            (click)="selectAllFeatures(false)">
      {{ 'textAnalysis.deselectAll' | translate }}
    </button>
  </div>
</div>

<div class="row">
  <div class="col-lg-auto">
    <div><label>{{ 'textAnalysis.language' | translate }}</label></div>
    <div>
      <select (change)="changeLanguage($event.target.value)"
              [(ngModel)]="language"
              name="language">
        <option value="de">{{ 'textAnalysis.german' | translate }}</option>
        <option value="en">{{ 'textAnalysis.english' | translate }}</option>
      </select>
    </div>
  </div>
  <div class="col-lg-auto">
    <div><label>{{ 'textAnalysis.documentType' | translate }}</label></div>
    <div>
      <select [(ngModel)]="documentType" style="width:200px"
              name="documentType"
              hideDelay="0"
              showDelay="0"
              [tooltip]="HtmlContent" contentType="template"
              (change)="changedDocumentType($event.target.value)">
        <option *ngFor="let documentType of filteredDocumentTypes"
                [ngValue]="documentType.name"
                title="{{documentType.description }}">
          {{documentType.uiName}}
        </option>
      </select>
      <ng-template #HtmlContent>
        {{documentTypeDescription}}
      </ng-template>
    </div>
  </div>
  <div class="col">
    <div class="row" style="margin-left:5px">
      <div *ngFor="let feature of features" class="col-auto feature"
           tooltip="<p><strong>{{feature.description}}</strong></p><p style='font-weight:normal'>{{feature.longDescription}}</p>"
           placement="bottom"
           theme="light"
           showDelay="0"
           hideDelay="0"
           [class.feature-selected]="feature.selected"
           (click)="selectFeature(feature)">
        {{feature.uiName}}
      </div>

    </div>


  </div>

</div>


