import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TextAnalysisComponent } from './text-analysis/text-analysis.component';
import { CompareComponent } from './text-analysis/compare.component';
import { TextAnalysisGuard } from './text-analysis/text-analysis.guard';
import { LogoutComponent } from './core/auth/logout-component';
import { IsCAUserGuard, isCAUserGuard } from './security/is-ca-user.guard';


const appRoutes: Routes =
  [
    { path: '', component: TextAnalysisComponent, pathMatch: 'full', canActivate: [() => inject(TextAnalysisGuard).canActivate(), isCAUserGuard ] },
    { path: 'text-analysis', component: TextAnalysisComponent, pathMatch: 'full', canActivate: [() => inject(TextAnalysisGuard).canActivate(), isCAUserGuard] },
    { path: 'compare', component: CompareComponent, pathMatch: 'full', canActivate: [() => inject(TextAnalysisGuard).canActivate(), isCAUserGuard] },
    { path: 'logout', component: LogoutComponent, pathMatch: 'full' },
  ];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
 // <-- debugging purposes only
 // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
