import { Directive, HostListener, OnInit, ElementRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { fromEvent, Subscription, Subject} from 'rxjs';
import { ProfilesService } from '../profiles.service';


@Directive({
  selector: '[profile-counter]'
})
export class ProfileResizeDirective implements OnInit, OnDestroy{

  private visibleProfilesCount: number;
  @Output() onVisibleProfilesChanged = new EventEmitter();
  private unsubscribe = new Subject();

  constructor(private elementRef: ElementRef,
              private profilesService: ProfilesService) { }
    ngOnDestroy(): void {
      this.unsubscribe.next(true);
      this.unsubscribe.complete();
    }

  ngOnInit(): void {
    this.profilesService.getAll()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.calculateVisibleProfiles();
      });   
  }
    

  @HostListener('window:resize')
  public onResize() {
    this.calculateVisibleProfiles();
  }

  calculateVisibleProfiles() {
    let height =35;
    this.visibleProfilesCount = Array.from(this.elementRef.nativeElement.children[0].children).filter(e => (e as HTMLElement).offsetTop < height).length;
    this.onVisibleProfilesChanged.emit(this.visibleProfilesCount);
  }
}
