import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalSelectorDirective } from './directives/global-selector/global-selector.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RedirectInterceptor } from './interceptors/redirect.interceptor';



@NgModule({
  declarations: [GlobalSelectorDirective],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RedirectInterceptor,
      multi: true,
    }],
  exports: [GlobalSelectorDirective]
})
export class SessionModule { }
