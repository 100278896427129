<h1>Upload file</h1>
<input #file type="file" (change)="upload(file.files)" />
<br />
<span style="font-weight:bold;color:green;" *ngIf="progress > 0 && progress < 100">
  {{progress}}%
</span>

<span style="font-weight:bold;color:green;">
  {{uploading}}
</span>
