import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit } from "@angular/core";
import { Subject } from "rxjs";
import { SelectedCriteria, Profile, TextAnalysisMode, ResultRequest } from "./text-analysis.models";
import { TextInputComponent } from "./text-input/text-input.component";
import { TextAnalysisService } from "./text-analysis.service";
import { takeUntil } from "rxjs/operators";
import { SessionStorageService } from "./session-storage.service";
import {SanitizationService} from '@app/core/sanitization.service';

declare var $: any;

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss']
})
export class CompareComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  selectedCriteria$ = new Subject<SelectedCriteria>();
  selectedProfile$ = new Subject<Profile>();
  textAnalysisMode$ = new Subject<TextAnalysisMode>();
  showCompareButton = true;
  isCompareTextProvided: boolean;

  @ViewChildren(TextInputComponent) textInputComponents: QueryList<TextInputComponent>;

  selectedCriteria: SelectedCriteria;
  includePatterns: boolean = this.sessionStorage.getValueFromSession("includePatterns") === 'false' ? false : true;
  maxContentLength: number = this.includePatterns ? 10000 : 25000;
  isTextCompared: boolean = false;

  constructor(private textAnalysisService: TextAnalysisService,
              private sessionStorage: SessionStorageService,
              private sanitizer: SanitizationService) { }

  ngOnInit(): void {
    this.selectedCriteria$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: SelectedCriteria) => {
        this.selectedCriteria = response;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  compare() {
    let leftText = this.sanitizer.escapedAndSanitized(this.textInputComponents.first.textContent);
    let rightText = this.sanitizer.escapedAndSanitized(this.textInputComponents.last.textContent);
    if (leftText?.length > this.maxContentLength || rightText?.length > this.maxContentLength) {
      $("#maximumTextLengthModal").modal('show');
    }
    else {
      this.textAnalysisService.compareTexts({
        language: this.selectedCriteria.language,
        leftText: leftText,
        leftResults: this.selectedCriteria.features.map(feature => {
          let result: ResultRequest = {
            includePattern: this.textInputComponents.first.includePatterns,
            includeScore: false,
            name: feature
          };
          return result;
        }),
        rightResults: this.selectedCriteria.features.map(feature => {
          let result: ResultRequest = {
            includePattern: this.textInputComponents.last.includePatterns,
            includeScore: false,
            name: feature
          };
          return result;
        }),
        rightText: rightText,
        type: this.selectedCriteria.documentType
      });
      this.isTextCompared = true;
    }
  }

  onIncludePatternsChanged(included: boolean) {
    this.maxContentLength = included ? 10000 : 25000;
    this.includePatterns = included;
  }

  onTextCompareChanged(isCompared: boolean) {
    this.isTextCompared = isCompared;
  }

  onTextChanged(compareText: string) {
    this.isCompareTextProvided = compareText?.length > 0;
  }
}
