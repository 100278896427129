import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap, takeUntil, catchError } from 'rxjs/operators';
import { Information } from '../../core/auth/auth.models';
import { interval, Subject, of, Observable } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { SessionInfo } from './session-info';
import { BroadcastChannel, createLeaderElection, LeaderElector } from 'broadcast-channel';

@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnDestroy {
  private idleInterval = null;
  private idmSessionTimer = null;
  private localSessionTimer = null;  
  private signoutUrl = "/account/logout";
  public idleTime = 0;
  private measureIdlePeriod = 30000; //30 sec
  private unsubscribe = new Subject();
  private sessionInformation: Information;
  private sessionChannel: BroadcastChannel;
  private elector: LeaderElector;
  private isLeaderTab: boolean = false;

  constructor(private http: HttpClient, private localStorage: LocalStorage) {
    this.sessionChannel = new BroadcastChannel('session');
    this.elector = createLeaderElection(this.sessionChannel);
    this.sessionChannel.onmessage = msg => {
      if (msg.idleTime != undefined && msg.idleTime != null) {
        console.log('sessionChannel.onmessage - msg.idleTime = ' + msg.idleTime);
        this.idleTime = msg.idleTime;
      }
      else if (msg.fromLeader) {
        this.isLeaderTab = false;
      }
    };
    this.elector.awaitLeadership().then(() => {
      console.log('Leader tab');
      this.isLeaderTab = true;
      this.sessionChannel.postMessage({ fromLeader: true });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    this.sessionChannel.close();
  }

  trackUserActivity(sessionInformation: Information) {
    this.sessionInformation = sessionInformation;

    //every minute extend session if there was user's activity
    //if no local activity check IDM session expiration time

    this.idmSessionRenewTimer();
    this.localSessionRenewTimer();

    this.idleInterval = setInterval(() => {
      if (this.idleTimePassed()) 
      {
        console.log("user is idle check");
        // Get session info only in Leader Tab
        this.elector.awaitLeadership().then(() => {
          console.log('Leader tab - handleUserIsIdle');
          this.handleUserIsIdle();
        });
      }
      else{
        console.log("user is idle - " + this.idleTime);
      }
    }, this.measureIdlePeriod);
  }

  getIdmSessionInfo():Observable<SessionInfo>{
    let idmEndpoint = `${this.sessionInformation.idmSessionUrl}Account/SessionInfo`;
      let r = this.http.get<SessionInfo>(idmEndpoint, {withCredentials: true})
      .pipe(catchError(e => {
        console.error(e);
        return of<SessionInfo>(null);
      }));
      return r;
  }

  async handleUserIsIdle() {
    let sessionInfo = await this.getIdmSessionInfo().toPromise();

    if (sessionInfo == null) {
      this.signOut();
      return;
    }

    var sessionActiveTill = new Date(sessionInfo.expires);
    console.log(sessionInfo, sessionActiveTill);
  }

  idleTimePassed(){
    this.idleTime += this.measureIdlePeriod;
    let signoutTimeout = this.sessionInformation.signoutTimeout - (this.sessionInformation.countdownTimeout * 1000);
    return this.idleTime >= signoutTimeout;
  }

  idmSessionRenewTimer() {
    if (this.idmSessionTimer) return;

    // Extend session only in Leader Tab
    this.elector.awaitLeadership().then(() => {
      console.log('Leader tab - idmSessionRenew');
      this.idmSessionRenew();
    });

    interval(this.sessionInformation.sessionRenewPeriod)
    .pipe(switchMap(
        () => {
          if (this.idleTime >= this.sessionInformation.sessionRenewPeriod || !this.isLeaderTab) {
            console.log("idle - do not renew idm session");
            return of({}); 
          }

          return this.idmSessionRenew();
        }), 
        takeUntil(this.unsubscribe)).
        subscribe(x => { });
  }

  idmSessionRenew() {
    console.log("active -  renew idm session");
    let idmEndpoint = `${this.sessionInformation.idmSessionUrl}Account/ExtendSessionLifetime`;
    let requestOptions = { withCredentials: true, responseType: 'text' as 'json' };
    return this.http.get(idmEndpoint, requestOptions).pipe(catchError(e => {
      console.error(e);
      this.signOut();
      return of({});
    }));
  }

  localSessionRenewTimer() {
    if (this.localSessionTimer) return;
    interval(this.sessionInformation.sessionRenewPeriod)
    .pipe(switchMap(() => this.localSessionRenew()), takeUntil(this.unsubscribe)).
      subscribe(x => { });
  }

  localSessionRenew() {
    let localEndpoint = "/api/ping";
    if (this.localSessionTimer) return;
    return this.http.get(localEndpoint).pipe(catchError(e => {
      console.error(e);
      this.signOut();
      return of({});
    }));
  }

  signOut() {
    clearInterval(this.idleInterval);
    this.localStorage.getItem<string>('lang')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lang: string) => {
        window.location.href = this.signoutUrl + '?lang=' + lang;
      });
    sessionStorage.clear();
  };
}
