<app-mobile></app-mobile>
<app-loader></app-loader>
<div *ngIf="userAuthenticated" class="container-fluid d-flex flex-column h-100">

  <app-nav-menu></app-nav-menu>

  <div class="flex-grow-1 flex-shrink-0 body-container">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>
