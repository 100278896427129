import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { CoreModule } from './core/core.module';
import { TextAnalysisModule } from './text-analysis/text-analysis.module';
import { AppRoutingModule } from './app-routing.module';
import { ErrorPagesModule } from './error-pages/error-pages.module';
import { UserDetailsModule } from './user-details/user-details.module';

import { LocalStorage } from '@ngx-pwa/local-storage';
import { Langs, DEFAULT_LANG } from './core/language.constants';
import { take } from 'rxjs/operators';
import { DocumentAnalysisComponent } from './document-analysis/document-analysis.component';
import { DocumentAnalysisModule } from './document-analysis/document-analysis.module';
import { SessionModule } from './session/session.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    TextAnalysisModule,
    DocumentAnalysisModule,
    UserDetailsModule,
    AppRoutingModule,
    SessionModule,
    TranslateModule.forRoot({
      loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    ErrorPagesModule // should be the last module because consists the routing wildcard
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, LocalStorage],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translateService: TranslateService,
  localStorage: LocalStorage): () => Promise<{}> {
  return () => new Promise(resolve => {

    const url = new URL(window.location.href);
    const qpLang = url.searchParams.get('lang');
    if (qpLang && (qpLang in Langs)) {
      localStorage.setItem('lang', qpLang)
        .pipe(take(1))
        .subscribe(() => { resolve(null); });
    }

    localStorage.getItem<string>('lang')
    .pipe(take(1))
    .subscribe((lang: string) => {
      let langToSet: string;
      if (lang && (lang in Langs)) {
        langToSet = lang;
      } else {
        langToSet = DEFAULT_LANG;
      }
      translateService.setDefaultLang(DEFAULT_LANG);
      translateService.use(langToSet);

      localStorage.setItem('lang', langToSet)
        .pipe(take(1))
        .subscribe(() => { resolve(null); });

      resolve(null);
    });

  });
}
