import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../core/auth/auth.service";
import { map, take } from "rxjs/operators";
import { LocalStorage } from "@ngx-pwa/local-storage";

@Injectable({
  providedIn: 'root',
})
export class TextAnalysisGuard  {
  constructor(private authService: AuthService,
    private router: Router,
    private localStorage: LocalStorage) { }

  canActivate(): Observable<boolean> {
    return this.authService.getUser()
      .pipe(
        map(user => {
          if (!user.authenticated) {
            this.localStorage.getItem<string>('lang')
              .pipe(take(1))
              .subscribe((lang: string) => {
                window.location.replace('/account/login?lang=' + lang);
              });

            return false;
          }

          return true;
        })
      )
  }
}
