import { Directive, HostListener, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { fromEvent, Subscription} from 'rxjs';
import { SessionService } from '../../services/session.service';
import { BroadcastChannel } from 'broadcast-channel';

@Directive({
  selector: 'div'
})
export class GlobalSelectorDirective implements OnInit, OnDestroy {
  public delay = 1000;
  private subscription: Subscription;
  private sessionChannel: BroadcastChannel;

  constructor(private sessionService: SessionService, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.sessionChannel = new BroadcastChannel('session');

    this.subscription = fromEvent(this.elementRef.nativeElement, 'mousemove').pipe(
      debounceTime(this.delay)).subscribe(element => this.resetIdleTime());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('keypress')
  @HostListener('click')
  public onKeyPress() {
    this.resetIdleTime();
  }

  public resetIdleTime() {
    this.sessionService.idleTime = 0;
    this.sessionChannel.postMessage({ idleTime: 0 });
  }
}
