import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'formatNumber' })
export class FormatNumberPipe implements PipeTransform {
  constructor() { }
  transform(value) {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
}
