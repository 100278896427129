import { NgModule } from '@angular/core';
import { CommonModule, formatNumber } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorComponent } from './components/error/error.component';
import { InputMaskDirective } from './directives/input-mask/input-mask.directive';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafeHtmlPipe } from './pipes/safe-html-pipe';
import { FormatNumberPipe } from './pipes/format-number-pipe';
import { ProgressBarComponent } from './components/progressbar/progressbar.component';

const NOTIFICATION_TIMEOUT = 20000;

@NgModule({
  declarations: [
    ErrorComponent,
    InputMaskDirective,
    SafeHtmlPipe,
    FormatNumberPipe,
    ProgressBarComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({'timeOut': NOTIFICATION_TIMEOUT})
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorComponent,
    InputMaskDirective,
    ToastrModule,
    SafeHtmlPipe,
    FormatNumberPipe,
    ProgressBarComponent
  ]
})
export class SharedModule { }
