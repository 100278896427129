<div class="row">
  <div class="col-lg-auto border-right">
    <button class="comanButton"
            (click)="openSaveProfilesDialog()">
      {{ 'textAnalysis.newProfile' | translate }}
    </button>
  </div>

  <div class="col profiles-container" profile-counter>
    <div class="row profile-container-closed"
         [@openClose]="profilesContainerOpened ? 'open' : 'closed'"
         (window:resize)="windowResized($event)"
         #profileContainer>
      <div *ngFor="let profile of profiles"
           [class.profile-selected]="profile.selected"
           class="col-auto profile"
           (click)="selectProfile(profile)">
        {{profile.name}}
      </div>
    </div>
  </div>

  <div class="col-lg-auto">
    <span class="profile-counter-container" *ngIf="numberOfProfileRows > 0">
      <span *ngIf="!profilesContainerOpened">
        +{{profiles?.length - visibleProfiles}}
        <span class="counter-delimiter">
          |
        </span>
      </span>
      <button class="expand-profile-button"
              (click)="toogleProfilesContainerSize()">
        <fa-icon [icon]="profilesContainerOpened ? icons.faChevronUp : icons.faChevronDown"></fa-icon>
      </button>
    </span>
    <button *ngIf="profiles?.length > 0" class="btn btn-secondary" style="margin-left: 5px"
            (click)="openDeleteConfirmationModal()">
      <fa-icon [icon]="icons.faTrash"></fa-icon>
    </button>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" id="saveProfileModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'textAnalysis.saveProfile' | translate}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form (ngSubmit)="submitOnEnter()">
          <label>{{'textAnalysis.profileName' | translate}}</label>
          <div class="form-group">
            <ng2-completer [(ngModel)]="profileName"
                           inputId="profileNameAutocomplete"
                           inputClass="form-control"
                           inputName="profileName"
                           openOnFocus="true"
                           [ngModelOptions]="{standalone: true}"
                           textNoResults=""
                           [datasource]="dataService" [minSearchLength]="0" [maxChars]="100"></ng2-completer>
            <div *ngIf="selectedCriteria && selectedCriteria.features.length === 0" class="alert alert-warning" role="alert">
              {{ 'textAnalysis.selectAtLeastOneFeature' | translate}}
            </div>
            <div *ngIf="showOverrideWarning" class="alert alert-warning" role="alert">
              {{ 'textAnalysis.profileWillBeOverridden' | translate}}
            </div>
            <div *ngIf="showSameResultsWarning" class="alert alert-warning" role="alert">
              {{ 'textAnalysis.profileWithSameCriteria' | translate}}
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-link" type="button" data-dismiss="modal">
          {{'textAnalysis.cancel' | translate}}
        </button>
        <button [disabled]="(!profileName ||
                  selectedCriteria.features.length === 0)
                  ? 'disabled': null"
                type="button" class="comanButton"
                (click)="saveProfile()">
          {{'textAnalysis.save' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" id="deleteProfileConfirmationModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'textAnalysis.deleteProfile' | translate}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ 'textAnalysis.deleteConfirmation' | translate: { profile_name: selectedProfileName } }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link"
                data-dismiss="modal">
          {{'textAnalysis.doNotDelete' | translate}}
        </button>
        <button type="button" class="comanButton"
                (click)="deleteProfile()">
          {{'textAnalysis.delete' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
