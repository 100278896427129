import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  AfterViewChecked,
  AfterViewInit,
  SecurityContext
} from '@angular/core';
import { faCopy, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ClipboardService } from "../../shared/services/clipboard.service";
import { SessionStorageService } from "../session-storage.service";
import { PatternGroup, ResultRequest, SelectedCriteria, TextAnalysisMode, TextAnalysisRequest } from "../text-analysis.models";
import { TextAnalysisService } from "../text-analysis.service";
import { TranslateService } from "@ngx-translate/core";
import {DomSanitizer} from '@angular/platform-browser';
import {SanitizationService} from '@app/core/sanitization.service';

declare var $: any;

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();

  @Input()
  selectedCriteria$: Subject<SelectedCriteria>;
  @Input()
  textAnalysisMode$: Subject<TextAnalysisMode>;
  @Input()
  title: string;
  @Input()
  showAnalyzeButton: boolean;
  @Input()
  useRightComparisonResult: boolean;
  @Input()
  includePatterns?: boolean;
  @Input()
  showCompareButton: boolean;
  @Input()
  isCompareTextProvided = true;
  @Input()
  panelID: string;

  @Output() includePatternsState = new EventEmitter();
  @Output() isTextComparedState = new EventEmitter();
  @Output() compareButtonClicked = new EventEmitter();
  @Output() textChangedEvent = new EventEmitter();
  selectedCriteria: SelectedCriteria;

  icons = {
    faCopy: faCopy,
    faEdit: faEdit,
    faTrash: faTrash
  };

  isTextAnalyzed: boolean = this.sessionStorage.getValueFromSession('isTextAnalyzed') === 'true' || false;
  selectedTab: string = this.sessionStorage.getValueFromSession("selectedTab") || '';
  patternGroups: PatternGroup[] = JSON.parse(this.sessionStorage.getValueFromSession('patternGroups'));

  @Input()
  maxTextContentLength?: number;
  textContent: string = '';
  translatedTitle: string;
  public delay = 300;
  @ViewChild('textArea') textArea: any;
  public get safeTextContent(): string{
    if (!this.textContent) {
      return this.textContent;
    }
    return this.sanitizer.escapedAndSanitized(this.textContent);
  }

  constructor(private textAnalysisService: TextAnalysisService,
    private clipboardService: ClipboardService,
    private sessionStorage: SessionStorageService,
    private translate: TranslateService,
              private sanitizer: SanitizationService) {
    this.includePatterns == null ? (this.sessionStorage.getValueFromSession("includePatterns") === 'true' || false) : this.includePatterns;
    this.maxTextContentLength = this.includePatterns ? 10000 : 25000;
  }

  ngOnInit(): void {
    this.isCompareTextProvided = (this.showCompareButton !== undefined && this.sessionStorage.getValueFromSession('compareText')?.length > 0) || this.selectedTab.indexOf('compare') === -1;
    this.textContent = this.title?.indexOf('comparisonText') != -1 ? this.sessionStorage.getValueFromSession('compareText') : (this.sessionStorage.getValueFromSession('textAnalysis') !== undefined ? this.sessionStorage.getValueFromSession('textAnalysis') : '');
    this.translate.stream(this.title)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((translation: string) => {
        this.translatedTitle = translation;
      });
    this.selectedCriteria$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.selectedCriteria = data;
        this.textAnalysisMode$.next(TextAnalysisMode.editMode);
        this.sessionStorage.storeDataInSession('isTextAnalyzed', false);
        this.sessionStorage.storeDataInSession('isTextCompared', false);
        this.isTextAnalyzed = false;
        this.isTextComparedState.emit(false);
      });

    this.textAnalysisService.analyzedText$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.textAnalysisMode$.next(TextAnalysisMode.analysisMode);
        this.isTextAnalyzed = true;
      });

    this.textAnalysisService.patternGroups$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.patternGroups = response;
      });

    this.textAnalysisMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.isTextAnalyzed = response === TextAnalysisMode.analysisMode ? true : false;
      });

    this.textAnalysisService.comparedTexts$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.textAnalysisMode$.next(TextAnalysisMode.analysisMode);
        this.patternGroups = this.useRightComparisonResult ? response.rightPatternGroups : response.leftPatternGroups;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  analyzeText(event: any): void {
    let isTextCompared = this.sessionStorage.getValueFromSession('isTextCompared');
    let compareText = this.sessionStorage.getValueFromSession('compareText');
    let yourText = this.sessionStorage.getValueFromSession('textAnalysis');

    if (compareText?.length > this.maxTextContentLength || yourText?.length > this.maxTextContentLength) {
      $("#maximumTextLengthModal").modal('show');
    }
    else {
      if (this.showCompareButton && isTextCompared === 'false' && this.selectedTab.indexOf('compare') !== -1) {
        this.compareButtonClicked.emit(true);
        this.sessionStorage.storeDataInSession('isTextCompared', true);
      }
      else if (this.isTextAnalyzed || (isTextCompared === 'true' && this.selectedTab.indexOf('compare') !== -1)) {
        this.editAnalysis();
      }
      else {
        let textAnalysis = this.getTextAnalysis();
        console.log(this.textContent, textAnalysis);
        this.textAnalysisService.analyzeText(textAnalysis);
        if (this.includePatterns) {
          this.textAnalysisService.patternGroups$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
              this.patternGroups = response;
              this.sessionStorage.storeDataInSession('patternGroups', JSON.stringify(this.patternGroups));
              this.sessionStorage.storeDataInSession('isTextAnalyzed', true);
              this.isTextAnalyzed = true;
            });
        }
        else {
          this.sessionStorage.storeDataInSession('textAnalysis', textAnalysis.document.text);
        }
      }
    }
  }

  getTextAnalysis(): TextAnalysisRequest {
    return {
      document: {
        text: this.safeTextContent,
        type: this.selectedCriteria.documentType
      },
      language: this.selectedCriteria.language,
      results: this.selectedCriteria.features.map(feature => {
        let result: ResultRequest = {
          includePattern: this.includePatterns,
          includeScore: false,
          name: feature
        };
        return result;
      })
    };
  }

  copyText() {
    this.clipboardService.copyMessage(this.textContent);
  }

  editAnalysis(): void {
    this.textAnalysisMode$.next(TextAnalysisMode.editMode);
    this.sessionStorage.storeDataInSession('isTextAnalyzed', false);
    this.sessionStorage.storeDataInSession('isTextCompared', false);
    this.isTextComparedState.emit(false);
    this.isTextAnalyzed = false;
  }

  clearText(): void {
    this.textContent = '';
    this.textAnalysisMode$.next(TextAnalysisMode.editMode);
    if (this.title.indexOf('comparisonText') === -1) {

      this.sessionStorage.storeDataInSession('isTextAnalyzed', false);
      this.sessionStorage.storeDataInSession('textAnalysis', '');
    }
    else {
      this.sessionStorage.storeDataInSession('isTextAnalyzed', false);
      this.sessionStorage.storeDataInSession('isTextCompared', false);
      this.sessionStorage.storeDataInSession('compareText', '')
      this.isCompareTextProvided = false;
      this.emitTextChanged(this.textContent);
    }
  }

  includePatternsChanged(checked: boolean): void {
    this.maxTextContentLength = checked ? 10000 : 25000;
    this.textAnalysisMode$.next(TextAnalysisMode.editMode);
    this.sessionStorage.storeDataInSession("includePatterns", this.includePatterns);
    this.sessionStorage.storeDataInSession('isTextCompared', false);
    this.includePatternsState.emit(this.includePatterns);
    this.isTextComparedState.emit(false);
  }

  textChanged(event: any): void {
    if (this.title.indexOf('comparisonText') === -1) {
      this.sessionStorage.storeDataInSession('textAnalysis', event.target.value);
    }
    else {
      this.sessionStorage.storeDataInSession('compareText', event.target.value);
      this.emitTextChanged(this.textContent);
    }
  }

  isAnalyzeButtonDisabled() {
    return !this.selectedCriteria ||
      this.selectedCriteria?.features.length === 0 ||
      this.textContent === null ||
      this.textContent?.length === 0 || this.isCompareTextProvided === false
      ? 'disabled' : null;
  }

  public emitTextChanged(value: string) {
    this.textChangedEvent.emit(value);
  }
}
