<div class="component-card">
  <app-criteria [selectedCriteria$]="selectedCriteria$"
                [selectedProfile$]="selectedProfile$"></app-criteria>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="component-card">
      <app-profile [selectedCriteria$]="selectedCriteria$"
                   [selectedProfile$]="selectedProfile$"></app-profile>
    </div>
  </div>
</div>

  <div class="card-deck">
      <app-text-input [title]="'textAnalysis.yourText'"
                      [selectedCriteria$]="selectedCriteria$"
                      [includePatterns] = "includePatterns"
                      (isTextComparedState) = "onTextCompareChanged($event)"
                      (includePatternsState)="onIncludePatternsChanged($event)"                      
                      [textAnalysisMode$]="textAnalysisMode$"
                      [showCompareButton]="showCompareButton"
                      (compareButtonClicked) ="compare()"
                      [maxTextContentLength]="maxContentLength"
                      [isCompareTextProvided] = "isCompareTextProvided"
                      [panelID] = "'leftPanel'"
                      class="component-card card border-0">
      </app-text-input>
      <app-text-input [title]="'textAnalysis.comparisonText'"
                      [selectedCriteria$]="selectedCriteria$"
                      [textAnalysisMode$]="textAnalysisMode$"
                      [includePatterns] = "includePatterns"
                      (isTextComparedState) = "onTextCompareChanged($event)"
                      (textChangedEvent) ="onTextChanged($event)"
                      [maxTextContentLength]="maxContentLength"
                      [useRightComparisonResult]="true"
                      [panelID] = "'rightPanel'"
                      class="component-card card border-0">
      </app-text-input>
  </div>

<div class="component-card">
  <app-compare-result [textAnalysisMode$]="textAnalysisMode$"></app-compare-result>
</div>


