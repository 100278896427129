<div class="row">
  <div class="col-auto">
    <h4>{{ 'textAnalysis.textAnalysisResult' | translate }}</h4>
  </div>
</div>

<ng-container *ngIf="showResults">
  <div class="row result-row" *ngFor="let feature of analyzedfeatures">
      <div class="col-xl-5 col-12 resultFeatureNameContainer"
           tooltip="<p><strong>{{feature.description}}</strong></p><p style='font-weight:normal'>{{feature.longDescription}}</p>"
           placement="bottom"
           theme="light"
           show-delay="700">
        {{feature.uiName}}
      </div>
      <div class="col-xl-5 col-10 resultsProgressBarContainer">
        <app-progressbar [percent]="feature.score" style="width: 100%"></app-progressbar>
      </div>
      <div class="col-xl-2 col-2 resultPercentContainer">
        {{feature.score}}%
      </div>
  </div>
</ng-container>



