import { OnInit, OnDestroy, Component, Input } from "@angular/core";

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressBarComponent {
  @Input()
  percent: number;

  @Input()
  rightToLeft: boolean = false;
}
