<div *ngIf="user.authenticated">
    <h1>{{'user.profileInfo' | translate}}:</h1>
    <h5>ID: {{user.id}}</h5>
    <h5>{{'user.roles' | translate}}: {{user.roles}}</h5>
    <h5>{{'user.claims' | translate}}: </h5>
    <ul class="table">
        <li *ngFor="let claim of user.claims">
            {{ claim.key }} - {{ claim.value }}
        </li>
    </ul>
</div>