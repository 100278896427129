import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FeaturesService } from "../features.service";
import { Feature, TextAnalysisMode } from "../text-analysis.models";
import { TextAnalysisService } from "../text-analysis.service";

@Component({
  selector: 'app-analysis-result',
  templateUrl: './analysis-result.component.html',
  styleUrls: ['./analysis-result.component.scss']
})
export class AnalysisResultComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();

  @Input()
  textAnalysisMode$: Subject<TextAnalysisMode>;

  allFeatures: Feature[] = [];
  analyzedfeatures: Feature[] = [];

  showResults: boolean = false;

  constructor(private textAnalysisService: TextAnalysisService,
    private featuresService: FeaturesService,
    private localStorage: LocalStorage,
    private translate: TranslateService) {

  }

  ngOnInit(): void {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getAllFeatures(event.lang);
    });

    this.textAnalysisService.analyzedText$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        let sorted = response.results.slice(0);
        sorted.sort((a, b) => a.percentile > b.percentile ? -1 : 1);

        this.analyzedfeatures = sorted.map(result => {
          let feature = this.allFeatures.find(f => f.name === result.feature);
          feature.score = result.percentile;
          return feature;
        });
      });

    this.localStorage.getItem<string>('lang')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lang: string) => {
        this.getAllFeatures(lang);
      });    

    this.textAnalysisMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.showResults = response === TextAnalysisMode.analysisMode ? true : false;
      });
  }

  private getAllFeatures(lang: string) {
    this.featuresService.getData(lang)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.allFeatures = data;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }
}
