import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Feature } from "../../text-analysis/text-analysis.models";
import { DocumentTypeService } from "../document-type.service";
import { FeaturesService } from "../features.service";
import { SessionStorageService } from "../session-storage.service";
import { DocumentType, Profile, SelectedCriteria } from "../text-analysis.models";

declare var $: any;

@Component({
  selector: 'app-criteria',
  templateUrl: './criteria.component.html',
  styleUrls: ['./criteria.component.scss']
})
export class CriteriaComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();

  isTextAnalyzed: boolean;

  language: string = this.sessionStorageService.getValueFromSession('selectedLanguage') || 'de';
  documentType: string = this.sessionStorageService.getValueFromSession('selectedDocumentType') || 'default';
  features: Feature[] = JSON.parse(this.sessionStorageService.getValueFromSession("selectedFeatures")) || null;

  documentTypeDescription: string;

  documentTypes: DocumentType[];
  filteredDocumentTypes: DocumentType[];

  @Input()
  selectedCriteria$: Subject<SelectedCriteria>;

  @Input()
  selectedProfile$: Subject<Profile>;
  profileSelected: boolean;

  profileName: string;

  constructor(private documentTypeService: DocumentTypeService,
    private featuresService: FeaturesService,
    private sessionStorageService: SessionStorageService,
    private localStorage: LocalStorage,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getCriteriaData(event.lang);
    });

    this.localStorage.getItem<string>('lang')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lang: string) => this.getCriteriaData(lang));

    this.selectedProfile$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedProfile: Profile) => {
        let isProfileSelectedValue = this.sessionStorageService.getValueFromSession('isProfileSelected');
        if (isProfileSelectedValue === null || isProfileSelectedValue === 'true') {
          this.sessionStorageService.storeDataInSession('isProfileSelected', true);
          this.profileSelected = true;
        }
        else {
          this.profileSelected = false;
          selectedProfile.selected = false;
        }
        if (this.profileSelected) {
          selectedProfile.selected = true;
          this.profileName = selectedProfile.name;
          if (this.features) {
            this.features.forEach(f => {
              f.selected = selectedProfile.features.find(x => x === f.name) ? true : false;
            });
            this.sessionStorageService.storeDataInSession('selectedFeatures', JSON.stringify(this.features));
            this.sessionStorageService.storeDataInSession("isProfileSelected", true);
            this.sessionStorageService.storeDataInSession('selectedLanguage', selectedProfile.language);
            this.sessionStorageService.storeDataInSession('selectedDocumentType', selectedProfile.documentType);
            this.language = selectedProfile.language;
            this.setFilteredDocumentTypes(selectedProfile.language);
            this.documentType = selectedProfile.documentType;
            this.setDocumentTypeDescription();
          }
          else {
            selectedProfile.selected = false;
            let storedFeatures = JSON.parse(this.sessionStorageService.getValueFromSession('selectedFeatures')) as Feature[];
            let selectedFeatures = storedFeatures?.filter(f => f.selected);
            this.features?.forEach(f => {
              f.selected = selectedFeatures.find(sf => sf.name === f.name) ? true : false;
            });
          }
          this.setFilteredDocumentTypes(this.language);
          this.emitCriteriaChanged();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  changeLanguage(selectedlanguage): void {
    this.setFilteredDocumentTypes(selectedlanguage);
    this.setDocumentTypeDescription();
    this.sessionStorageService.storeDataInSession("selectedLanguage", selectedlanguage);
    this.sessionStorageService.storeDataInSession("isProfileSelected", false);
    if (!this.documentType ||
      !this.filteredDocumentTypes ||
      !this.filteredDocumentTypes.find(dc => dc.name === this.documentType)) {
      this.documentType = 'default';
      this.sessionStorageService.storeDataInSession('selectedDocumentType', this.documentType);
    }
    this.isTextAnalyzed = false;
    this.emitCriteriaChanged();
  }

  selectFeature(feature: Feature): void {
    console.log($("tooltip").text());
    feature.selected = !feature.selected;
    this.isTextAnalyzed = false;
    this.sessionStorageService.storeDataInSession("selectedFeatures", JSON.stringify(this.features))
    this.sessionStorageService.storeDataInSession("isProfileSelected", false);
    this.emitCriteriaChanged();
  }

  selectAllFeatures(selected: boolean): void {
    this.features.forEach(f => {
      f.selected = selected;
    });
    this.sessionStorageService.storeDataInSession("selectedFeatures", JSON.stringify(this.features))
    this.sessionStorageService.storeDataInSession("isProfileSelected", false);
    this.emitCriteriaChanged();
  }

  emitCriteriaChanged(): void {
    if (this.features) {
      this.selectedCriteria$.next({
        documentType: this.documentType,
        features: this.features.filter(f => f.selected).map(f => f.name),
        language: this.language
      });
    }
  }

  changedDocumentType(selectedDocumentType: string) {
    this.setDocumentTypeDescription();
    this.isTextAnalyzed = false;
    this.sessionStorageService.storeDataInSession('selectedDocumentType', selectedDocumentType.split(': ')[1]);
    this.sessionStorageService.storeDataInSession("isProfileSelected", false);
    this.emitCriteriaChanged();
  }

  setDocumentTypeDescription() {
    var selected = this.documentTypes?.find(x => x.name === this.documentType);
    if (selected) {
      this.documentTypeDescription = selected.description;
    }
  }

  setFilteredDocumentTypes(selectedlanguage: string) {
    this.filteredDocumentTypes = this.documentTypes?.filter(doc => doc.languages.find(lang => lang === selectedlanguage));
  }

  private getCriteriaData(lang: string) {

    this.featuresService.getData(lang)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.features = data.map(f => {
          let feature: Feature = {
            name: f.name,
            description: f.description,
            longDescription: f.longDescription,
            score: f.score,
            selected: false,
            uiName: f.uiName
          };

          if (this.features) {
            feature.selected = this.features.find(x => x.name === f.name)?.selected;
          }

          return feature;
        });
        this.sessionStorageService.storeDataInSession('selectedFeatures', JSON.stringify(this.features));
        this.emitCriteriaChanged();
      });

    this.documentTypeService.getData(lang)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.documentTypes = data;
        this.documentType = this.sessionStorageService.getValueFromSession('selectedDocumentType') || 'default';
        this.setDocumentTypeDescription();
        this.setFilteredDocumentTypes(this.language);
        this.emitCriteriaChanged();
      });
  }
}
