import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject,} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from './core/auth/auth.service'; 
import { SessionService } from './session/services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();
  userAuthenticated: boolean = false;

  constructor(private authService: AuthService, private sessionService: SessionService) { }

  ngOnInit() {
    this.authService.ping()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.authService.getUser()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((user) => {
            if (user.authenticated) {
              this.userAuthenticated = true;
            }
          });
      });
    this.authService.info().pipe(takeUntil(this.unsubscribe)).subscribe((info) => this.sessionService.trackUserActivity(info));
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }
}
