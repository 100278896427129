import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap, take } from "rxjs/operators";
import { Location } from '@angular/common';
import { LocalStorage } from "@ngx-pwa/local-storage";
import { AuthService } from "../../core/auth/auth.service";

@Injectable({
  providedIn: 'root'
})

export class RedirectInterceptor implements HttpInterceptor {
  constructor(private location: Location, private localStorage: LocalStorage, private authService: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        const response = err as HttpErrorResponse;
        if (response.status === 401 || response.status === 403) {
          this.authService.removeAccessToken();
          this.localStorage.getItem<string>('lang')
            .pipe(take(1))
            .subscribe((lang: string) => {
              this.location.go('/account/login?returnUrl=' + location.origin + '&lang=' + lang);
              window.location.reload();
            });
        }
      }
    }));
  }
}
