import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { User } from '../auth/auth.models';
import { AuthService } from '../auth/auth.service';
import { AuthConstants } from '../auth/auth.constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionStorageService } from '../../text-analysis/session-storage.service';
import { Langs, DEFAULT_LANG } from '../language.constants';

/* eslint-disable */

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})

export class NavMenuComponent implements OnInit, OnDestroy {
  isExpanded = false;
  langs = Langs;
  keys = Object.keys;
  currentLang: string;
  user: User = {
    authenticated: false,
    id: null,
    roles: null,
    claims: null,
    isAdmin: false,
    isCAMarketing: false,
    isCAClient: false,
    displayName: null,
    image: null
  };

  private unsubscribe = new Subject();

  constructor(private translate: TranslateService,
    private localStorage: LocalStorage,
    private authService: AuthService,
    private sessionStorage: SessionStorageService) { }

  ngOnInit() {
    this.localStorage.getItem<string>('lang')
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((lang: string) => {
      if (lang) {
        this.currentLang = lang;
      } else {
        this.currentLang = DEFAULT_LANG;
      }
    });

    this.authService.getUser()
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      res => {
        this.user = res;
      });
  }

  collapse(event) {
    this.isExpanded = false;
    this.sessionStorage.storeDataInSession("selectedTab", event.target.href);
    this.sessionStorage.storeDataInSession('patternGroups', JSON.stringify([]));
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  signout() {
    this.authService.removeAccessToken();
    this.localStorage.getItem<string>('lang')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((lang: string) => {
        document.location.href = '/account/logout?lang=' + lang;
      });
    sessionStorage.clear();
  }

  onLanguageChange($event) {
    const lang = $event.currentTarget.value;
    this.localStorage.setItem('lang', lang)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(() => {});
    this.translate.use(lang);
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }
}
