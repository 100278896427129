import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { Profile } from "./text-analysis.models";
import { HttpClient } from '@angular/common/http';
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {
  constructor(private http: HttpClient) { }

  public getAll(): Observable<Profile[]> {
    return this.http.get<Profile[]>(this.getBaseUrl()).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  public updateProfile(profile: Profile): Observable<Profile> {
    if (profile.id) {
      return this.http.put<any>(this.getBaseUrl(), profile).pipe(
        catchError((error) => {
          console.log(error);
          return throwError(error);
        }),
        map((response) => {
          return profile;
        })
      );
    }
    else {
      return this.http.post<Profile>(this.getBaseUrl(), profile).pipe(
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
    }
  }

  public deleteProfile(id: string): Observable<any> {
    return this.http.delete<any>(`${this.getBaseUrl()}/${id}`).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  public selectProfile(id: string): Observable<any> {
    return this.http.get<any>(`${this.getBaseUrl()}/select/${id}`).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  private getBaseUrl() {
    return '/api/profiles';
  }
}
