<div class="overlay d-flex d-lg-none align-items-center">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 d-block d-sm-none">
                <div class="d-flex pb-5 justify-content-center">
                    <img src="../../../assets/img/KPMG_logo_white.svg" class="img-fluid">
                    <h1 class="text-white pl-3">Atlas</h1>
                </div>
            </div>
            <div class="col-sm-3 offset-sm-1 col-6 offset-3">
                <img src="../../../assets/img/smartphone.svg" class="img-fluid w-100">
            </div>
            <div class="col-sm-6 offset-sm-1">
                <div class="d-sm-flex pb-5 d-none">
                    <img src="../../../assets/img/KPMG_logo_white.svg" class="img-fluid">
                    <h1 class="text-white pl-3">Atlas</h1>
                </div>
                <p class="font-weight-bold lead text-white text-center text-sm-left pt-5 pt-sm-0">{{'navigation.noMobile' | translate}}</p>
            </div>
        </div>
    </div>
</div>
