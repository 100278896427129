<div class="component-card">
  <app-criteria [selectedCriteria$]="selectedCriteria$"
                [selectedProfile$]="selectedProfile$"></app-criteria>
</div>

<div class="row">
  <div class="col-md-8">
    <div class="component-card">
      <app-profile [selectedCriteria$]="selectedCriteria$"
                   [selectedProfile$]="selectedProfile$"></app-profile>
    </div>
    <div class="component-card">
      <app-text-input [showAnalyzeButton]="true"
                      [title]="'textAnalysis.textAnalysis'"
                      [includePatterns] = "includePatterns"
                      [selectedCriteria$]="selectedCriteria$"
                      (includePatternsState)="onIncludePatternsChanged($event)" 
                      [maxTextContentLength]="maxContentLength"
                      [textAnalysisMode$]="textAnalysisMode$">
      </app-text-input>
    </div>
  </div>
  <div class="col-md-4">
    <div class="component-card component-card-right">
      <app-analysis-result [textAnalysisMode$]="textAnalysisMode$"></app-analysis-result>
    </div>
  </div>
</div>
