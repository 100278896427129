import { catchError } from 'rxjs/operators';
import { Observable, throwError, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TextAnalysisResponse, TextAnalysisRequest, PatternGroup, CompareTextsRequest, CompareTextsResponse } from './text-analysis.models';

@Injectable({
  providedIn: 'root'
})
export class TextAnalysisService {
  private analyzedTextSubject$ = new Subject<TextAnalysisResponse>();
  analyzedText$ = this.analyzedTextSubject$.asObservable();

  private patternGroupsSubject$ = new Subject<PatternGroup[]>();
  patternGroups$ = this.patternGroupsSubject$.asObservable();

  private comparedTextsSubject$ = new Subject<CompareTextsResponse>();
  comparedTexts$ = this.comparedTextsSubject$.asObservable();

  constructor(private http: HttpClient) { }

  public analyzeText(textAnalysisRequest: TextAnalysisRequest) {
    this.http.post<TextAnalysisResponse>(`${this.getBaseUrl()}api/textAnalysis`, textAnalysisRequest).pipe(catchError((error) => {
      console.error(error);
      return throwError(error);
    })).subscribe((response) => {
      this.analyzedTextSubject$.next(response);
      this.patternGroupsSubject$.next(this.generatePatternGroups(response));
    });
  }

  public compareTexts(compareTextsRequest: CompareTextsRequest) {
    let leftAnalysis: TextAnalysisRequest = {
      document: {
        text: compareTextsRequest.leftText,
        type: compareTextsRequest.type
      },
      language: compareTextsRequest.language,
      results: compareTextsRequest.leftResults
    };

    this.http.post<TextAnalysisResponse>(`${this.getBaseUrl()}api/textAnalysis`, leftAnalysis).pipe(catchError((error) => {
      console.error(error);
      return throwError(error);
    })).subscribe((leftResponse) => {
      let rightAnalysis: TextAnalysisRequest = {
        document: {
          text: compareTextsRequest.rightText,
          type: compareTextsRequest.type
        },
        language: compareTextsRequest.language,
        results: compareTextsRequest.rightResults
      };

      this.http.post<TextAnalysisResponse>(`${this.getBaseUrl()}api/textAnalysis`, rightAnalysis).pipe(catchError((error) => {
        console.error(error);
        return throwError(error);
      })).subscribe((rightResponse) => {
        this.comparedTextsSubject$.next({
          leftResults: leftResponse.results,
          leftPatternGroups: this.generatePatternGroups(leftResponse),
          rightResults: rightResponse.results,
          rightPatternGroups: this.generatePatternGroups(rightResponse)
        });
      });
    });
  }

  private generatePatternGroups(response: TextAnalysisResponse): PatternGroup[] {
    let patternGroups: PatternGroup[] = [];

    response.results.forEach((result, rI) => {
      result.patterns.forEach((pattern, pI) => {
        let needsToCreateLastEmpty: boolean = true;
        let reducedPattern = {
          from: pattern.from,
          to: pattern.to
        };

        for (var i = 0; i < patternGroups.length; i++) {
          let group = patternGroups[i];

          if (reducedPattern.to < group.from) { //  [] ()
            patternGroups.splice(i, 0, this.buildSlot(result.feature, pattern.weight, reducedPattern.from, reducedPattern.to));
            needsToCreateLastEmpty = false;
            break;
          }

          if (reducedPattern.from < group.from && reducedPattern.to <= group.to && reducedPattern.to >= group.from) { //   [ ( ] )
            patternGroups.splice(i, 0, this.buildSlot(result.feature, pattern.weight, reducedPattern.from, group.from - 1));

            if (reducedPattern.to < group.to) {
              patternGroups.splice(i, 0, {
                features: group.features.concat([{ feature: result.feature, weight: pattern.weight, uiName: result.feature }]),
                from: group.from,
                to: reducedPattern.to
              });
              group.from = reducedPattern.to + 1;
            }
            else {
              group.features.push({ feature: result.feature, weight: pattern.weight, uiName: result.feature });
            }

            needsToCreateLastEmpty = false;
            break;
          }

          if (reducedPattern.from >= group.from && reducedPattern.to <= group.to) {  //  ( [] )
            if (reducedPattern.from > group.from) {
              patternGroups.splice(i, 0, {
                features: group.features.slice(0),
                from: group.from,
                to: reducedPattern.from - 1
              });

              group.from = reducedPattern.from;
              i++;
            }

            if (reducedPattern.to < group.to) {
              let rightGroupToInsert: PatternGroup = {
                features: group.features.slice(0),
                from: reducedPattern.to + 1,
                to: group.to
              }

              if (i === patternGroups.length - 1) {
                patternGroups.push(rightGroupToInsert);
              }
              else {
                patternGroups.splice(i + 1, 0, rightGroupToInsert);
              }

              group.to = reducedPattern.to;
            }

            group.features.push({
              feature: result.feature,
              weight: pattern.weight,
              uiName: result.feature
            });

            needsToCreateLastEmpty = false;
            break;
          }

          if (reducedPattern.from >= group.from && reducedPattern.to > group.to && reducedPattern.from <= group.to) { // ( [ ) ]
            let rightGroupToInsert: PatternGroup = {
              features: group.features.concat([{ feature: result.feature, weight: pattern.weight, uiName: result.feature }]),
              from: reducedPattern.from,
              to: group.to
            }

            if (reducedPattern.from > group.from) {
              if (i === patternGroups.length - 1) {
                patternGroups.push(rightGroupToInsert);
              }
              else {
                patternGroups.splice(i + 1, 0, rightGroupToInsert);
              }

              group.to = reducedPattern.from - 1;
            }
            else {
              group.features.push({ feature: result.feature, weight: pattern.weight, uiName: result.feature });
            }

            reducedPattern.from = rightGroupToInsert.to + 1;
          }

          if (reducedPattern.from < group.from && reducedPattern.to > group.to) {
            patternGroups.splice(i, 0, this.buildSlot(result.feature, pattern.weight, reducedPattern.from, group.from - 1));
            group.features.push({
              feature: result.feature,
              weight: pattern.weight,
              uiName: result.feature
            });
            reducedPattern.from = group.to + 1;
            i++;
          }

        }

        if (needsToCreateLastEmpty) {
          patternGroups.push(this.buildSlot(result.feature, pattern.weight, reducedPattern.from, reducedPattern.to));
        }
      })
    });

    patternGroups.forEach(group => {
      group.features = group.features.sort((a, b) => a.weight <= b.weight ? 1 : -1);
    });

    return patternGroups;
  }

  private buildSlot(feature, weight, from, to): PatternGroup {
    return {
      features: [{
        feature: feature,
        weight: weight,
        uiName: feature
      }],
      from: from,
      to: to
    };
  }

  private getBaseUrl() {
      return "/";
  }


}
