import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../core/auth/auth.service';
import { User, Claim } from '../core/auth/auth.models';
import {map, take} from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class IsCAUserGuard {

  constructor(private authService: AuthService,
    private router: Router,
    private localStorage: LocalStorage) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    console.log('IsCAUserGuard#canActivate called');
    return this.authService.getUser()
      .pipe(map<User, boolean>(x => {
        if (!x.authenticated) {
          this.localStorage.getItem<string>('lang')
            .pipe(take(1))
            .subscribe((lang: string) => {
              window.location.replace('/account/login?lang=' + lang);
            });
        }
        else if (x.isCAMarketing || x.isCAClient) {
          return true;
        }
        else {
          this.router.navigate(['/403']);
          return false;
        }
      }));
  }
}

export const isCAUserGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  console.log('IsCAUserGuard#canActivate called');
  const authService = inject(AuthService);
  const router = inject(Router);
  const localStorage = inject(LocalStorage);
  return authService.getUser()
    .pipe(map<User, boolean>(x => {
      if (!x.authenticated) {
         localStorage.getItem<string>('lang')
          .pipe(take(1))
          .subscribe((lang: string) => {
            window.location.replace('/account/login?lang=' + lang);
          });
      }
      else if (x.isCAMarketing || x.isCAClient) {
        return true;
      }
      else {
        router.navigate(['/403']);
        return false;
      }
    }));
}

