
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentType } from './text-analysis.models';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {

  constructor(private http: HttpClient) { }

  public getData(lang: string): Observable<DocumentType[]> {
    return this.http.get<DocumentType[]>(this.getBaseUrl() + 'api/documentType?lang=' + lang).pipe(catchError((error) => {
      console.error(error);
      return throwError(error);
    }));
  }

  private getBaseUrl() {
    return "/";
  }
}
