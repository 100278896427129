import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  constructor() { }

  public storeDataInSession(key: any, value: any) {
    var current = sessionStorage.getItem(key);
    if (current) {
      sessionStorage.removeItem(key);
    }
    sessionStorage.setItem(key, value);
  }

  public getValueFromSession(key: any) {
    return sessionStorage.getItem(key);
  }
}
