<nav class="navbar navbar-expand-lg bg-light row mb-4">
  <span alt="KPMG" class="navbar-brand m-1 logo-svg logo-svg-blue" style="font-size: 6em;"></span>
  <h1 class="navbar-brand">{{'navigation.comunicationAnalytics' | translate}}</h1>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarColor02">
    <ul class="navbar-nav d-flex align-items-lg-center ml-lg-4">
      <li [routerLinkActive]='["active"]' [routerLinkActiveOptions]='{ exact: true }' class="nav-item">
        <a [routerLink]='["/"]' (click)='collapse($event)' class="nav-link">{{'navigation.textAnalysis' | translate}}</a>
      </li>

      <li [routerLinkActive]='["active"]' [routerLinkActiveOptions]='{ exact: true }' class="nav-item">
        <a [routerLink]='["/compare"]' (click)='collapse($event)' class="nav-link">{{'navigation.textComparison' | translate}}</a>
      </li>
    </ul>
    <ul class="navbar-nav d-flex align-items-lg-center ml-auto">
      <li class="nav-item">
        <div class="form-group mb-0">
          <select (change)="onLanguageChange($event)" [ngModel]="currentLang">
            <option value="en" *ngFor="let lang of keys(langs)" [value]="lang">{{langs[lang]}}</option>
          </select>
        </div>
      </li>
      <li [routerLinkActive]='["active"]' class="nav-item dropdown">
        <a href="#"  [ngClass]="{ 'dropdown-toggle':user.authenticated }" class="nav-link user-info flex-auto ml-auto d-flex align-items-center " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div *ngIf="user.image" class="d-none d-md-flex flex-1 flex-column user-image">
            <img [src]="user.image" alt="User image" />
          </div>
          <div *ngIf="user.authenticated" class="d-flex flex-2 flex-column m-sm-0 mx-md-3 pull-right user-text">
            {{user.displayName}}
          </div>
          <div *ngIf="!user.authenticated" class="d-flex flex-2 flex-column m-sm-0 mx-md-3 pull-right user-text">
            {{'user.naLine1' | translate}}<br /> {{'user.naLine2' | translate}}
          </div>
        </a>
        <div *ngIf="user.authenticated" class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="" (click)="signout()">{{'user.signOut' | translate}}</a>
        </div>
      </li>

    </ul>
  </div>
</nav>
