
export interface Feature {
  name: string;
  uiName: string;
  description: string;
  longDescription: string;
  score: number;
  selected: boolean;
}

export interface DocumentType {
  name: string;
  languages: [];
  uiName: string;
  description: string;
}

export interface TextAnalysisRequest {
  language: string;
  document: Document;
  results: ResultRequest[];
}

export interface CompareTextsRequest {
  language: string;
  type: string;
  leftText: string;
  rightText: string;
  leftResults: ResultRequest[];
  rightResults: ResultRequest[];
}

export interface Document {
  text: string;
  type: string;
}

export interface ResultRequest {
  name: string;
  includePattern: boolean;
  includeScore: boolean;
}

export interface TextAnalysisResponse {
  results: ResultResponse[];
}

export interface CompareTextsResponse {
  leftResults: ResultResponse[];
  rightResults: ResultResponse[];
  leftPatternGroups: PatternGroup[];
  rightPatternGroups: PatternGroup[];
}

export interface ResultResponse {
  feature: string;
  percentile: number;
  patterns: Pattern[];
}

export interface Pattern {
  from: number;
  to: number;
  weight: number;
}

export interface PatternGroup {
  from: number;
  to: number;
  features: PatternGroupFeature[];
}

export interface PatternGroupFeature {
  feature: string;
  weight: number;
  uiName: string;
}

export interface SelectedFeaturesChangedEventArgs {
  selectedFeatures: Feature[];
  unselectedFeatures: Feature[];
}

export interface Profile {
  id: string,
  name: string,
  features: string[],
  language: string,
  documentType: string;
  selected: boolean;
}

export interface SelectedCriteria {
  language: string;
  documentType: string;
  features: string[];
}

export enum TextAnalysisMode {
  editMode,
  analysisMode
}

export interface ComparisonResultItem {
  leftFeature: Feature,
  rightFeature: Feature
}
