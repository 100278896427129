import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { DocumentAnalysisComponent } from './document-analysis.component';
import { UploadComponent } from './upload/upload.component';

@NgModule({
  declarations: [
    DocumentAnalysisComponent,
    UploadComponent
  ],
  imports: [
    SharedModule,
    FontAwesomeModule,
    TooltipModule
  ]
})
export class DocumentAnalysisModule { }
