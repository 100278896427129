import { Component, OnInit, OnDestroy } from "@angular/core";
import { UploadService } from "../upload.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  private unsubscribe = new Subject();

  public progress: number;
  public uploading: boolean;

  constructor(private uploadService: UploadService) { }

  ngOnInit(): void {
    this.uploading = false;

    this.uploadService.uploading
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.progress = response.percent;
      });

    this.uploadService.uploaded
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.uploading = false;
        console.log(response.id);
      });
  }

  upload(files): void {
    if (files.length === 0) {
      return;
    }

    this.uploading = true;
    const formData = new FormData();

    for (let file of files) {
      formData.append(file.name, file);
    }

    this.uploadService.uploadFile(formData);
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

}
