<div class="row justify-content-center">
  <div class="col-2" style="text-align:center">
    <h4>{{'textAnalysis.comparisonResult' | translate }}</h4>
  </div>
</div>

<ng-container *ngIf="showResults">
  <div class="row result-row" *ngFor="let result of comparisonResult">
    <div class="col-5">
      <div class="row justify-content-end">
        <div class="col-11">
          <div class="row justify-content-end">
            <div class="percentBarContainer">
              <app-progressbar [percent]="result.leftFeature.score" [rightToLeft]="true" style="width: 100%">
              </app-progressbar>
            </div>
          </div>
        </div>

        <div class="col-1 scoreContainer">{{result.leftFeature.score}}%</div>
      </div>
    </div>
    <div class="col-2">
      <div style="width:100%;text-align:center"
           tooltip="<p><strong>{{result.leftFeature.description}}</strong></p><p style='font-weight:normal'>{{result.leftFeature.longDescription}}</p>"
           placement="bottom"
           theme="light"
           show-delay="700">
        {{result.leftFeature.uiName}}
      </div>
    </div>
    <div class="col-5">
      <div class="row">
        <div class="col-1 scoreContainer">{{result.rightFeature.score}}%</div>
        <div class="col-11">
          <div class="percentBarContainer">
            <app-progressbar [percent]="result.rightFeature.score" style="width: 100%">
            </app-progressbar>
          </div>
        </div>

      </div>

    </div>
  </div>
</ng-container>
