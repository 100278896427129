<div class="mh-12">
  <div class="row">
    <div class="col-auto mr-auto">
      <h4>{{ title | translate }}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col pb-2 pb-xl-0">
      <div class="data-privacy">{{'textAnalysis.dataPrivacy' | translate}}</div>
    </div>
    <div class="col-xl-auto col-12">
      <div class="row no-gutters align-items-center">
        <div class="col-auto ml-auto pr-2" [ngStyle]="translatedTitle?.indexOf('Comparison') != -1 ? {'max-width.px': '200'} : translatedTitle?.indexOf('Vergleichstext') != -1 ? {'max-width.px': '180'} : ''">
          <label class="textCounter">{{textContent?.length ? textContent?.length : 0 | formatNumber}} / {{maxTextContentLength | formatNumber}}</label>
        </div>
        <div class="col-auto px-2 d-flex align-items-center">
          <div class="custom-control custom-checkbox mb-0">
            <input type="checkbox" class="custom-control-input" [id]="panelID" [disabled]="title.indexOf('comparisonText') != -1"
            [(ngModel)]="includePatterns"
            (change)="includePatternsChanged($event)">
            <label class="custom-control-label" [for]="panelID">{{ 'textAnalysis.showPatterns' | translate }}</label>
          </div>
        </div>
        <div class="col-auto px-2" role="group">
          <button type="button" class="btn secondary-button"
                  (click)="copyText()">
            <fa-icon [icon]="icons.faCopy"></fa-icon>
          </button>
        </div>
        <div class="col-auto pl-2 pr-0">
          <button type="button" class="btn secondary-button"
                  (click)="clearText()">
            <fa-icon [icon]="icons.faTrash"></fa-icon>
          </button>
        </div>
        <div class="col-auto pl-3" *ngIf="title.indexOf('comparisonText') === -1">
          <button type="button" [ngClass]="isTextAnalyzed === true ? 'editButton' : 'analysisButton'"
                  [disabled]="(
                      !selectedCriteria ||
                      selectedCriteria?.features.length === 0 ||
                      textContent === null ||
                      textContent?.length === 0 || isCompareTextProvided === false
                    ) ? 'disabled' : null"
                  (click)="analyzeText($event)">
            <fa-icon *ngIf="isTextAnalyzed" [icon]="icons.faEdit"></fa-icon>
            {{isTextAnalyzed === true ? ('textAnalysis.editText' | translate) : (showCompareButton ? ('textAnalysis.compare' | translate) : ('textAnalysis.analyzeText' | translate))}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row row-border-botton-line"></div>


<div class="row">
  <textarea *ngIf="isTextAnalyzed===false"
            #textArea
            class="form-control" rows="12"
            [(ngModel)]='textContent'
            placeholder="{{'textAnalysis.insertTextHere' | translate }}"
            (input)="textChanged($event)"
            [attr.maxlength]="maxTextContentLength">
  </textarea>
  <app-text-highlight *ngIf="isTextAnalyzed"
                      [textContent]="safeTextContent"
                      [patternGroups]="patternGroups">
  </app-text-highlight>
</div>

<div class="modal" tabindex="-1" role="dialog" id="maximumTextLengthModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        {{'textAnalysis.maximumNuberOfCharacters' | translate: { value: (maxTextContentLength) } }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link"
                data-dismiss="modal">
          {{'textAnalysis.ok' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
