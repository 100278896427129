import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, OnChanges, ChangeDetectorRef, AfterViewChecked } from "@angular/core";
import { faChevronDown, faChevronUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { CompleterData, CompleterService } from "@tibelian/ng2-completer";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ProfilesService } from "../profiles.service";
import { SessionStorageService } from "../session-storage.service";
import { Profile, SelectedCriteria } from "../text-analysis.models";

declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '*',
        overflowY: 'hidden'
      })),
      state('closed', style({
        height: '35px',
        overflowY: 'hidden'
      })),
      transition('open => closed', [
        animate('0.25s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ])
    ])
  ]
})
export class ProfileComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();

  profiles: Profile[];
  dataService: CompleterData;

  @Input()
  selectedCriteria$: Subject<SelectedCriteria>;
  selectedCriteria: SelectedCriteria;

  @Input()
  selectedProfile$: Subject<Profile>;
  selectedProfileName: string;

  icons = {
    faTrash: faTrash,
    faChevronDown: faChevronDown,
    faChevronUp: faChevronUp
  };

  profileName: string;
  showOverrideWarning: boolean;
  showSameResultsWarning: boolean;
  visibleProfiles: number;
  numberOfProfileRows: number;
  @ViewChild('profileContainer') profileContainer: any;

  profilesContainerOpened: boolean = false;

  constructor(private profilesService: ProfilesService,
    private completerService: CompleterService,
    private sessionStorage: SessionStorageService) {

  }

  onResized(element) {
    let height = 35;
    this.visibleProfiles = Array.from(element?.nativeElement?.children).filter(e => (e as HTMLElement)?.offsetTop < height)?.length;
    this.numberOfProfileRows = this.visibleProfiles > 0 ? this.profiles?.length - this.visibleProfiles : 0;
  }

  ngOnInit(): void {
    this.profilesService.getAll()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data) {
          this.profiles = data;
          this.setCompleterData();
          var selected = this.profiles.find(p => p.selected);
          if (selected) {
            this.setSelectedProfileAsFirst(selected);
            this.emitSelectedProfile(selected);
          }
        }
        setTimeout(() => this.onResized(this.profileContainer), 0);
      });

    this.selectedCriteria$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((criteria) => {
        this.selectedCriteria = criteria;

        if (this.profiles) {
          var profile = this.profiles.find(p => p.selected);
          if (profile && !this.featuresAreTheSame(profile)) {
            profile.selected = false;
          }
          setTimeout(() => this.onResized(this.profileContainer), 0);
        }
      });

    setInterval(() => {
      this.profileNameChanged();
    }, 300);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  setCompleterData(): void {
    this.dataService = this.completerService.local(this.profiles, 'name', 'name');
  }

  selectProfile(profile: Profile): void {
    if (!profile.selected) {
      this.profilesService.selectProfile(profile.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.profiles.forEach(p => {
            p.selected = false;
          });
          this.sessionStorage.storeDataInSession('isProfileSelected', true);
          this.setSelectedProfileAsFirst(profile);
          this.emitSelectedProfile(profile);
          setTimeout(() => this.onResized(this.profileContainer), 0);
        });
    }
  }

  setSelectedProfileAsFirst(profile) {
    this.profiles = [profile].concat(
      this.profiles.filter(p => p.name !== profile.name));
  }

  openSaveProfilesDialog(): void {
    this.profileName = '';
    $("#saveProfileModal").modal('show');

    this.showSameResultsWarning = this.selectedCriteria.features.length > 0 && this.profiles.find(p => this.featuresAreTheSame(p)) ? true : false;
  }

  featuresAreTheSame(profile: Profile): boolean {
    return this.selectedCriteria.documentType === profile.documentType &&
      this.selectedCriteria.language === profile.language &&
      profile.features.length === this.selectedCriteria.features.length &&
      !this.selectedCriteria.features.find(f => !profile.features.find(x => x === f));
  }

  submitOnEnter() {
    if (this.profileName.trim() && this.selectedCriteria.features.length > 0) {
      this.saveProfile();
    }
  }

  saveProfile() {
    this.profileName = this.profileName.trim();
    if (this.profileName) {
      let selectedProfile = this.profiles.find(prof => prof.name === this.profileName);
      let featuresToAdd = this.selectedCriteria.features.map(feature => feature);
      if (selectedProfile) {
        selectedProfile.features = featuresToAdd;
      }

      this.profilesService.updateProfile({
        features: featuresToAdd,
        id: selectedProfile ? selectedProfile.id : undefined,
        name: this.profileName,
        documentType: this.selectedCriteria.documentType,
        language: this.selectedCriteria.language,
        selected: true
      })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          if (!selectedProfile) {
            this.profiles.push(data);
            this.setCompleterData();
          }

          $("#saveProfileModal").modal('hide');
          this.profiles.forEach(p => { p.selected = false; });
          data.selected = true;
          this.sessionStorage.storeDataInSession('isProfileSelected', true);
          this.setSelectedProfileAsFirst(data);
          this.emitSelectedProfile(data);
          setTimeout(() => this.onResized(this.profileContainer), 0);
        });

    }
    else {
      this.profileNameChanged();
    }
  }

  profileNameChanged() {
    this.showOverrideWarning = this.profileName && this.profiles.find(p => p.name === this.profileName) ? true : false;
  }

  openDeleteConfirmationModal() {
    let toDelete = this.profiles.find(p => p.selected);
    if (toDelete) {
      $("#deleteProfileConfirmationModal").modal('show');
    }
  }

  deleteProfile() {
    let toDelete = this.profiles.find(p => p.selected);
    if (toDelete) {
      this.profilesService.deleteProfile(toDelete.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          $("#deleteProfileConfirmationModal").modal('hide');
          this.profiles.splice(this.profiles.findIndex(p => p.id === toDelete.id), 1);
          this.setCompleterData();
          setTimeout(() => this.onResized(this.profileContainer), 0);
        });
    }
  }

  emitSelectedProfile(profile: Profile) {
    this.selectedProfileName = profile.name;
    this.selectedProfile$.next(profile);
  }

  toogleProfilesContainerSize() {
    this.profilesContainerOpened = !this.profilesContainerOpened;
    setTimeout(() => this.onResized(this.profileContainer), 0);
  }

  windowResized($event: any) {
    this.onResized(this.profileContainer);
  }
}
